
import React, { useState, useEffect } from "react";

import { Navigate, useOutletContext } from "react-router-dom";

export default function HomeView() {
  const {profile} = useOutletContext();
  const redirectPath = profile.isLoggedIn ? '/challenges/oldvsnew' : '/user/login'

  return (
    <Navigate to={redirectPath} replace/>
  );
}

