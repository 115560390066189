import React, { useState, useEffect, useReducer } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, orderBy } from 'firebase/firestore';
import { transformFirestoreDates } from '../../common/utils';
import { db } from '../../common/firebase';
import moment from 'moment';

const getDefaultData = () => {
  return {
    active: false,
    signup_deadline: moment().subtract(1, 'month').toDate(),
    start_date: moment().add(1, 'month').toDate(),
    end_date: moment().add(2, 'month').toDate()
  }
};

// get teams?
export default function useChallenge(challengeId) {
    const [value, setValue] = useState(getDefaultData());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      setLoading(true);
      setValue(getDefaultData());
      const docRef = doc(db, 'challenges', challengeId);
      const unlisten = onSnapshot(docRef, (snap) => {
        const data = snap.exists ? transformFirestoreDates(snap.data()) : getDefaultData();
        // console.log('got challenge', docPath, snap.data());
        setValue(data);
        setLoading(false);
      }, (err) => setError(err));
      return unlisten;
    }, [challengeId]);

    const isHidden = function() {
      return !value.active;
    };
    const signupsAllowed = function() {
      return !isHidden() && value.signup_deadline && moment().isBefore(value.signup_deadline);
    };
    const isActive = function() {
      return isFinalLogDay() || (!isHidden() && value.start_date && value.end_date && moment().isBetween(value.start_date, value.end_date, undefined, '(]'));
    };
    const isFirstDay = function() {
      return value.start_date && moment().isSame(value.start_date, 'day');
    };
    const isFinalLogDay = function() {
      return value.end_date && moment().subtract(1, 'day').isSame(value.end_date, 'day');
    };
    const hasEnded = function() {
      return moment().isAfter(value.end_date) && !isFinalLogDay();
    };

    return {
      ...value,
      loading,
      error,
      isHidden,
      isActive,
      signupsAllowed,
      isFirstDay,
      isFinalLogDay,
      hasEnded
    };
  }
