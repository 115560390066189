import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


export default
function ChallengeHeader({challenge}) {
  // TODO: add image_url to challenge settings
  return (
    <div className="header-oldvsnew my-4 flex flex-col items-center text-center text-white font-bold">
      <div className="bg-oldvsnew-grad-top self-stretch grow flex flex-col justify-center">Current Challenge</div>
      <div className="py-2"><img src="/img/oldvsnew.png" alt="Old vs New"/></div>
      <div className="bg-oldvsnew-grad-bottom self-stretch grow flex flex-col justify-center">
        <div><Link to="rules" className="no-underline">Read the Challenge Rules &gt;</Link></div>
      </div>
    </div>
  )
}
