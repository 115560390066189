import React, { useState, useEffect } from 'react';
import { Link, useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import ChallengeHeader from '../../components/challenge/ChallengeHeader';

import moment from 'moment';
import useChallengeUser from '../../hooks/challenge/useChallengeUser';
import { ErrorPage, FullscreenLoadingPage, transformFirestoreDates } from '../../common/utils';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import LogActivityEntry from '../../components/challenge/activity/LogActivityEntry';

function UserEntryItem({entryData, userRef}) {
  const [user, loading, error] = useDocumentData(userRef, {idField: '$id', transform: transformFirestoreDates});
  // TODO: Don't show anything until loaded?
  //        and pass any errors to parent?
  //        or we could just add the user display name to the user_challenges docs...
  if (loading) {
    return <div></div>;
  }
  return (
    <div className="flex justify-between items-center border-b border-gray-300 py-1 mb-1">
    </div>
  );
}

export default function UserView() {
  const {challenge, profile} = useOutletContext();
  const {userId} = useParams();
  const user = useChallengeUser({challengeId: challenge.key, userId});

  // console.log('user ', userId, user);
  if (user.error) {
    console.error('user view error', user.error);
    return <ErrorPage message="Could not load user details"/>;
  }
  if (user.loading) {
    return <FullscreenLoadingPage/>;
  }

  return (
    <div>
      <div className="mt-3">
        <div className="text-gray-400 font-extrabold">{moment().format('ddd, MMM D')}</div>
        <div className="text-2xl font-extrabold">Hi, {profile.name}</div>
      </div>
      <ChallengeHeader challenge={challenge}/>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-3 mb-4">
        <div className="challenge-card text-center">
          <div className="text-xl font-extrabold">{user.display_name}</div>
          <div className="text-sm">User</div>
        </div>
        <div className="challenge-card text-center">
          <div className="text-xl font-extrabold">{user.team.name}</div>
          <div className="text-sm">Team</div>
        </div>
        <div className="challenge-card text-center">
          <div className="text-xl font-extrabold">{user.signup.points}</div>
          <div className="text-sm">Points</div>
        </div>
        <div className="challenge-card md:col-span-3">
          <div className="text-xl font-extrabold">Activity</div>
          {user.activity && user.activity.map(entry => {
            entry.user_name = user.display_name;
            return <LogActivityEntry key={entry.$id} entry={entry} team={user.team}/>
          })}
        </div>
      </div>
    </div>
  );
}
