import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCdLB_8vY3pSXQWOgfHkcnIkEko4jIPISQ",
  authDomain: "elevation-fit.firebaseapp.com",
  projectId: "elevation-fit",
  storageBucket: "elevation-fit.appspot.com",
  messagingSenderId: "619790702049",
  appId: "1:619790702049:web:91ae9368c7a8249331eea0"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, 'us-east1');


// Should we use this? maybe nested? {USERS: {INDEX: 'users', CHALLENGS: 'user_challenges'...}}
const collections = {
  // Users
  USERS: 'users',
  USER_CHALLENGES: 'user_challenges',
  USER_LOGS: 'user_logs',
  // Teams
  TEAMS: 'teams',
  // Challenges
  CHALLENGES: 'challenges',
  CHALLENGE_USERS: 'challenge_users',
  CHALLENGE_TEAMS: 'challenge_teams',

}

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8081);
}

export {
  db,
  auth,
  functions,
  firebaseApp,
  collections
};
