// Challenge routes
import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from '../providers/auth.provider';

import ChallengeView from '../views/challenges/ChallengeView';
import ChallengeLayout from '../views/challenges/ChallengeLayout';
import ChallengeListView from '../views/challenges/ChallengeListView';
import ChallengeRulesView from '../views/challenges/ChallengeRulesView';

import TeamView from '../views/teams/TeamView';
import UserView from '../views/challenges/UserView';

// /challenges/*
export default function ChallengeRoutes() {
  //
  return (
    <RequireAuth andRegistration>
        <Routes>
          <Route index element={<ChallengeListView/>}/>
          <Route path=':challengeKey/*' element={<ChallengeLayout/>}>
            <Route index element={<ChallengeView/>}/>
            {/* TODO: not a route */}
            {/* <Route path='log' element={<ChallengeLog/>}/> */}
            {/* TODO: need a profile page (generic, no challenge details) */}
            {/*       and a challenge-specific profile page (my log and my team) */}
            <Route path='rules' element={<ChallengeRulesView/>}/>
            {/* Teams are listed on the challenge view */}
            {/* <Route path='teams' element={<TeamListView/>}/> */}
            <Route path='teams/:teamId' element={<TeamView/>}/>
            <Route path='users/:userId' element={<UserView/>}/>
          </Route>
        </Routes>
    </RequireAuth>
  );
}

