import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, createDoc, updateDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { useDocument, useDocumentData } from 'react-firebase-hooks/firestore';

import { LoadingPage, transformFirestoreDates } from '../../common/utils';
import { db } from '../../common/firebase';

function ChallengeListItem(props) {
  const challengeLink = '/challenges/' + props.id;
  return <div>Challenge: {props.name} <Link to={challengeLink}>Join Challenge</Link></div>;
}
//
export default function ChallengeList({ userId }) {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) {
       // this is to handle logout state
       // (probably need to change the way logout works though - can we useEffect in the logout route to call signOut?).
      return;
    }
    setLoading(true);
    // TODO: Move all this stuff to an api component (like the date transform and all that)
    // import { getChallengeList } from '../../api/challenges.api';
    // getUserChallenges in a users api or a challenges api?
    const challengesRef = collection(db, 'challenges');
    let challengesQuery = query(challengesRef, where("active", "==", true))
    const userChallengesRef = collection(db, 'users', userId, 'user_challenges');
    // whoops. supposed to do onSnapshot dumby
    const challengesPromise = getDocs(challengesQuery).then((querySnapshot) => {
      // console.log('challenges result');
      let challengeList = [];
      querySnapshot.forEach((snap) => {
        const challenge = transformFirestoreDates(snap.data());
        challenge.id = snap.id;
        challengeList.push(challenge);
      });
      setChallenges(challengeList);
    });
    Promise.all([challengesPromise]).then(() => {
      setLoading(false);
    }).catch(err => setError(err));

    // filter by "signup_deadline" here instead of in the query. (OR just query the user's challenges also...)
    // actually. for the challenges, we could use useCollection - then, useEffect to load the users challenges
  }, [userId]);

  if (error) {
    console.log('challenges error ', error);
    return <div>Error</div>;
  }
  if (loading) {
    return <LoadingPage/>
  }
  if (challenges.length === 1) {
    const onlyChallenge = challenges[0];
    // check challenge signup_deadline >= now
    // check if user is already signed up
    const signupDoc = doc(db, 'users', userId, 'user_challenges', onlyChallenge.id);
    // auto join
    // createDoc(signupDoc, {challenge_ref: onlyChallenge})
    // return <div>navigate here. signup automatically</div>
  }

  // console.log('challenges', challenges);
  return (
    <div>
      {/* TODO: Auto-join */}
      {challenges.map(challenge => <ChallengeListItem key={challenge.id} {...challenge}/>)}
    </div>
  );
}

