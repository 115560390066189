import React, { useState, useEffect, useReducer } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, orderBy, collectionGroup, where } from 'firebase/firestore';
import { transformFirestoreDates } from '../../common/utils';
import { db } from '../../common/firebase';
import useChallengeSignup from './useChallengeSignup';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const defaultTeam = {name: '', points: 0};
export default function useChallengeUser({challengeId, userId}) {
    const [userData, userLoading, userError] = useDocumentData(doc(db, 'users', userId));
    const signup = useChallengeSignup({challengeId, userId});
    const [team, setTeam] = useState(defaultTeam);
    const [activity, setActivity] = useState([]);

    const [loadingTeam, setLoadingTeam] = useState(true);
    const [loadingActivity, setLoadingActivity] = useState(true);
    const [challengeUserError, setChallengeUserError] = useState(null);

    const loading = userLoading || signup.loading || loadingTeam || loadingActivity;
    const error = challengeUserError || userError || signup.error;
    // console.log('loading' ,userLoading, signup.loading, loadingTeam, loadingActivity);

    useEffect(() => {
      if (!signup.team) {
        return;
      }
      setLoadingTeam(true);
      setLoadingActivity(true);
      const teamRef = doc(db, 'challenges', challengeId, 'challenge_teams', signup.team.id);
      const userEntriesRef = query(collection(db, 'users', userId, 'user_challenges', challengeId, 'user_entries'), orderBy('last_update', 'desc'));

      const unlistenTeam = onSnapshot(teamRef, (snap) => {
        const data = snap.exists ? snap.data() : defaultTeam;
        data.$id = snap.id || signup.team.id;
        setTeam(data);
        setLoadingTeam(false);
      }, (err) => setChallengeUserError(err));
      const unlistenEntries = onSnapshot(userEntriesRef, (entriesSnap) => {
        // console.log('user entries', entriesSnap.docs);
        const entriesResult = [];
        entriesSnap.docs.forEach((snap) => {
          const data = snap.data();
          data.$id = snap.id;
          entriesResult.push(transformFirestoreDates(data));
        });
        setActivity(entriesResult);
        setLoadingActivity(false);
      }, (err) => setChallengeUserError(err));
      return () => {
        unlistenTeam && unlistenTeam();
        unlistenEntries && unlistenEntries();
      };
    }, [challengeId, userId, signup.team]);

    return {
      ...userData,
      team,
      signup,
      activity,
      loading,
      error
    }
  }
