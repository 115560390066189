// import logo from './logo.svg';
import './App.css';
import './static/css/app.css';
// import './static/css/common.css';
import React, { useState, useEffect } from "react";

import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';

import { ProfileProvider, useProfile } from './providers/profile.provider';

import AppRouter from './AppRouter';

function App(props) {
  //
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(null);
  useEffect(() => {
    // TODO: Remove logging
    // console.log('location', lastLocation, ' -> ', location);
    setLastLocation(location);
  }, [location]);

  return (
    <ProfileProvider>
      <AppRouter/>
    </ProfileProvider>
  );
}

export default App;
