

// export { default as Hero1 } from './hero-1.png';
// export { default as Hero2 } from './hero-2.png';
// export { default as Hero3 } from './hero-3.png';
import Hero1 from './hero-1.png';
import Hero2 from './hero-2.png';
import Hero3 from './hero-3.png';

const heroImages = [Hero1, Hero2, Hero3];
export {
    heroImages
}
