import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useOutletContext } from 'react-router-dom';

import { LoadingPage } from '../../../common/utils';
import RegistrationForm from '../../../components/user/RegistrationForm';

function RegisterView() {
  //
  const navigate = useNavigate();
  const {profile} = useOutletContext();

  if (!profile.isLoggedIn) {
    // console.log('not logged in, redirecting');
    return <Navigate to="../login" replace/>;
  }
  if (!profile.exists) {
    // console.log('user does not exist, redirecting');
    return <Navigate to="../logout" replace/>;
  }
  if (profile.isRegistered) {
    // console.log('already registered, redirecting');
    return <Navigate to="/challenges" replace/>;
  }

  const handleConfirm = function() {
    // console.log('success! registered');
    navigate('/challenges', {replace: true});
  };//

  // write to db in component
  // pass ref as param? component still knows about firebase, but doesn't need to know about location in DB
  // unless... api/client could handle the update fn, since this one doesn't need to be realtime
  return (
    <div className="p-4 mt-4 max-w-5xl mx-auto">
      <div className="px-2">
        <h2 className="text-2xl font-extrabold">Registration</h2>
        <div>Please confirm your information</div>
        <div className="text-xs">If any of the information below is incorrect, please contact <a href="mailto:bbuhler@elevationchurch.org">Buck</a></div>
      </div>
      <div className="bg-gray-100 rounded-lg mt-4 p-2">
        <RegistrationForm profile={profile} onConfirm={handleConfirm}/>
      </div>
    </div>
  );
}

export default RegisterView;
