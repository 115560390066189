import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import { collection } from 'firebase/firestore';

// import { LoadingPage } from '../../common/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faTint, faBible, faRunning } from '@fortawesome/pro-regular-svg-icons';

import ChallengeList from '../../components/challenge/ChallengeList';

import { db } from '../../common/firebase';
import moment from 'moment';


export default function ChallengeRulesView() {
  const {challenge, profile, signup} = useOutletContext();
  // TODO: load profile
  // console.log('challenge view', profile, challenge);
  // <FontAwesomeIcon icon={faAppleAlt} />
  // TODO: if admin, show button to view challenge (different route?)
  return (
    <div className="mt-8">
<div className="pt-3">
  <div>Scoresheet printouts to track your points:</div>
  <ul className="list-disc list-inside mb-4 text-sm pl-4">
    <li><a href="/dl/ec-fit-scoreboard-oldschool.pdf" rel="noreferrer" target="_blank">OLD SCHOOL</a></li>
    <li><a href="/dl/ec-fit-scoreboard-mid.pdf" rel="noreferrer" target="_blank">MID SCHOOL</a></li>
    <li><a href="/dl/ec-fit-scoreboard-newschool.pdf" rel="noreferrer" target="_blank">NEW SCHOOL</a></li>
  </ul>
</div>
<div className="pt-1">
  Follow <a href="https://www.instagram.com/efitstaff/" target="_blank" rel="noreferrer" className="no-underline font-bold">@ecfitstaff</a> on Instagram for challenge updates!
</div>
<div className="py-3">
  <div><a href="/2022-oldvsnew-challenge.pdf" target="_blank">Download the full challenge details [PDF]</a></div>
  <div><a href="/dl/oldvsnew-faith-ideas.pdf" target="_blank">Ideas for the FAITH component [PDF]</a></div>
</div>
<strong className="text-oldvsnew font-extrabold">WHEN</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>Tuesday, February 22nd to Monday, April 4th</li>
  <li>6 weeks (42 days)</li>
  <li>Register by Tuesday, February 15th</li>
</ul>

<strong className="text-oldvsnew font-extrabold">TEAMS</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>Staff &amp; Spouses will sign up and be assigned to a team based on staff tenure.</li>
  <li>The are 3 teams: OLD SCHOOL, MID SCHOOL &amp; NEW SCHOOL.</li>
  <li>Shirts with a team color will be provided and potential bonus points awarded.</li>
</ul>

<strong className="text-oldvsnew font-extrabold">4 DAILY FOCUSES</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>FAITH: min of 15 minutes of bible study/devotional time. Listening to worship while working out doesn't count. In addition to the Essentials study, <a href="/dl/oldvsnew-faith-ideas.pdf" target="_blank">download this PDF</a> for some ideas that you can use for the FAITH component.</li>
  <li>FOOD: No added sugar. See the PDF for more details.</li>
  <li>FITNESS: 100 Myzone MEPS or 40 minutes of challenging exercise (relative to one's current fitness level). Don't already have a MYZONE Heart Rate Monitor? Request one <a href="https://forms.gle/9TQFF6BPLpozC3ri8">here</a> or reach out to Buck with any questions. </li>
  <li>FUEL: minimum of 100 fl oz of water. If under 200lbs, at least half of your bodyweight in fl oz of water.
    <ul className="list-disc list-inside text-sm pl-4"><li>e.g. 150 lb person should drink a minimum of 75 oz of water.</li></ul>
  </li>
</ul>

<strong className="text-oldvsnew font-extrabold">POINTS</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>INDIVIDUAL GOAL
    <ul className="list-disc list-inside text-sm pl-4">
      <li>Must earn 30 out of 42 points in each focus/category to complete the challenge.</li>
      <li>That's 120 points (30 in each category) out of 168 total points.</li>
    </ul>
  </li>
  <li>TEAM GOAL
    <ul className="list-disc list-inside text-sm pl-4">
      <li>The team with the highest point total based on an average per person will win.</li>
      <li>Tiebreaker: Team with the highest average Myzone MEPS per person.</li>
    </ul>
  </li>
</ul>

<strong className="text-oldvsnew font-extrabold">Prizes</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>INDIVIDUAL
    <ul className="list-disc list-inside text-sm pl-4">
      <li>Choice of backpack or lunch cooler.</li>
    </ul>
  </li>
  <li>WINNING TEAM
    <ul className="list-disc list-inside text-sm pl-4">
      <li>Scheduled free day off for the team which includes an optional trip to the Whitewater Center.</li>
      <li>Local activity option with day off for remote staff.</li>
      <li>While all participants' points count toward their team, completing the individual challenge is required to be eligible for the team prize.</li>
    </ul>
  </li>
</ul>

<strong className="text-oldvsnew font-extrabold">BONUS INDIVIDUAL MYZONE CHALLENGE</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>The Top 20 Myzone MEP leaders will receive gift cards ranging from $50-$200.</li>
  <li>MEPS are limited to the Green, Yellow and Red Zones only.</li>
  <li>Individuals must complete the individual challenge to qualify for this prize as well.</li>
</ul>

<strong className="text-oldvsnew font-extrabold">WEBSITE</strong>
<ul className="list-disc list-inside mb-4 text-sm pl-4">
  <li>Logging will take place on our new EFIT site with a 24 hour grace period for points
    <ul className="list-disc list-inside text-sm pl-4">
      <li>e.g. You have until 11:59pm on Wednesday to enter your points earned on Tuesday. Then your points expire.</li>
    </ul>
  </li>
  <li>Registration link will be sent to all staff and spouses. Registration deadline is noon on Tuesday, February 15th.</li>
</ul>
<br/><br/><br/>

    </div>
  );
}

// TODO: don't need withLoadingProfile
// export default withLoadingProfile(ChallengeHome, LoadingPage);
