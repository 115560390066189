

import { db, auth, functions } from '../common/firebase';

import { doc, collection, getDoc, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';


export async function loadChallenge(id) {
    // dispatch, getState
}
export async function getChallengeProfile(userId, challengeId) {
    let ref = doc(db, 'users', userId, 'user_challenges', challengeId);
    return getDoc(ref).then(snap => snap.data());
}
export async function getChallenge(id) {
    let ref = doc(db, 'challenges', id);
    return getDoc(ref).then(snap => snap.data());
}
export async function getUserTeam({userId, challengeId}) {
    let signupRef = doc(db, 'users', userId, 'user_challenges', challengeId);
    let signupSnap = await getDoc(signupRef);
    let teamRef = signupSnap.exists && signupSnap.get('team');
    if (!teamRef) {
        return null;
    }
    let teamSnap = await getDoc(teamRef);
    return {...teamSnap.data(), $id: teamSnap.id};
};

export async function joinChallenge({challengeId, profile}) {
    const joinChallengeFn = httpsCallable(functions, 'joinChallenge');
    // console.log('joining challenge');
    const result = await joinChallengeFn({challengeId, userId: profile.id});
    // console.log('joined', result);
    return result;
    // const data = result.data || {};
    // return data;
}
