import React, { useState, useEffect, useReducer } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, orderBy, collectionGroup, where } from 'firebase/firestore';
import { transformFirestoreDates } from '../../common/utils';
import { db } from '../../common/firebase';

const defaultSignup = {points: 0};
export default function useChallengeSignup({challengeId, userId}) {
    const [signup, setSignup] = useState(defaultSignup);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      setLoading(true);
      const docRef = doc(db, 'users', userId, 'user_challenges', challengeId);
      const unlisten = onSnapshot(docRef, (snap) => {
        const data = snap.exists ? snap.data() : defaultSignup;
        // console.log('got entry', docPath, snap.data());
        setSignup(data);
        setLoading(false);
      }, (err) => setError(err));
      return unlisten;
    }, [challengeId, userId]);

    return {
      ...signup,
      loading,
      error
    }
  }
