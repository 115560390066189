import React, { useState, useEffect } from 'react';

import { collection, doc, getDocs, getDoc, query, limit, orderBy } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../../../common/firebase';
import { transformFirestoreDates } from '../../../common/utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function TeamItem({team, place, onClick}) {
  const placeText = {1: '1st', 2: '2nd', 3: '3rd'};
  const placeColors = {1: 'bg-gold', 2: 'bg-silver', 3: 'bg-bronze'};
  // const placeBorder = {1: 'border-gold', 2: 'border-silver', 3: 'border-bronze'};
  return (
    <div className="flex justify-between items-center border-b border-gray-300 py-1 mb-1 cursor-pointer" onClick={onClick}>
      <div className={"rounded-full py-2 w-10 text-center font-extrabold text-sm " + (placeColors[place] || 'bg-silver')}>{placeText[place] || ''}</div>
      <div className="grow px-4">
        <div className="font-extrabold">{team.name}</div>
        <div className="text-xs text-gray-500">{team.average || 0} point{team.average === 1 ? '' : 's'}</div>
      </div>
      <div>&gt;</div>
    </div>
  )
}

export default function TeamLeaderboard({challenge}) {
  const [teamList, loading, error] = useCollectionData(query(collection(db, 'challenges', challenge.key, 'challenge_teams'), orderBy('average', 'desc')), {idField: '$id'});

  const navigate = useNavigate();

  const selectTeam = function(teamId) {
    return navigate('teams/' + teamId);
  };

  const teams = teamList || [];
  return (
    <div>
      {teams.map((team, index) => {
        return <TeamItem key={team.$id} team={team} place={index + 1} onClick={() => selectTeam(team.$id)}/>
      })}
    </div>
  )
}
