import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import ChallengeHeader from '../../components/challenge/ChallengeHeader';

import moment from 'moment';
import useChallengeTeam from '../../hooks/challenge/useChallengeTeam';
import { ErrorPage, FullscreenLoadingPage, transformFirestoreDates } from '../../common/utils';
import { useDocumentData } from 'react-firebase-hooks/firestore';

function TeamMemberListItem({signupData, userRef}) {
  const [user, loading, error] = useDocumentData(userRef, {idField: '$id', transform: transformFirestoreDates});
  const navigate = useNavigate();
  // TODO: Don't show anything until loaded?
  //        and pass any errors to parent?
  //        or we could just add the user display name to the user_challenges docs...
  if (loading) {
    return <div></div>;
  }
  return (
    <div className="flex justify-between items-center border-b border-gray-300 py-1 mb-1 cursor-pointer" onClick={() => navigate('../users/' + user.$id)}>
      <div>
        <div className="font-extrabold">{user.display_name}</div>
        <div className="text-xs text-gray-500">{signupData.points} points</div>
      </div>
      <div className="text-xs text-gray-500">{signupData.last_log &&  'Logged ' + moment(signupData.last_log).fromNow()}</div>
    </div>
  );
}

export default function TeamView() {
  const {challenge, profile} = useOutletContext();
  const {teamId} = useParams();
  const {team, loading, error} = useChallengeTeam({challengeId: challenge.key, teamId});
  // console.log('team ', teamId, team);
  if (error) {
    console.error('team view error', error);
    return <ErrorPage message="Could not load team details"/>;
  }
  if (loading) {
    return <FullscreenLoadingPage/>;
  }
  const teamMembers = team.members || [];
  return (
    <div>
      {/* TODO: Move some of this into the challenge layout? */}
      <div className="mt-3">
        <div className="text-gray-400 font-extrabold">{moment().format('ddd, MMM D')}</div>
        <div className="text-2xl font-extrabold">Hi, {profile.name}</div>
      </div>
      <ChallengeHeader challenge={challenge}/>
      <div className="grid gap-4 grid-cols-2 mb-4">
        <div className="challenge-card text-center">
          <div>
            <div className="text-xl font-extrabold">{team.name}</div>
            <div className="text-sm font-light">Team</div>
          </div>
        </div>
        <div className="challenge-card text-center">
          <div>
            <div className="text-xl font-extrabold">{team.average || 0}</div>
            <div className="text-sm font-light text-center">
              <div>Points</div>
              <span className="text-xs text-gray-400 italic">(average)</span>
            </div>
          </div>
          {/* TODO: either show total or show some other stats?  */}
          {/* TODO: show current place */}
          {/* <div>
            <div className="text-xl font-extrabold">{team.points || 0}</div>
            <div className="text-sm font-light text-center">Total Points</div>
          </div> */}
        </div>
        <div className="challenge-card col-span-2">
          <div className="flex justify-between mb-4">
            <div className="font-extrabold text-xl">Team members</div>
            {/* <div className=""><button className="rounded-xl shadow-sm py-2 px-4 bg-white font-extrabold">View All</button></div> */}
          </div>
          <div>
            {teamMembers.map(member => {
              return <TeamMemberListItem key={member.user.path} signupData={member} userRef={member.user} />
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
