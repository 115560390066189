import React, { useState, useEffect, useReducer } from 'react';
import { useOutletContext, Link, useNavigate, Navigate } from 'react-router-dom';
// import { db } from '../common/firebase';

export default function ProfileInfoView() {
  const {profile} = useOutletContext();
  // TODO: what about users/{userId} ?
  // user/profile is wrong
  // should be /account/ or /profile/
  // and /users/* = other users
  return (
    <div>ProfileInfo: {profile.id}</div>
  )
};

