import { useEffect, useCallback, useMemo, useState, useReducer, createContext, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { db, auth, functions } from '../common/firebase';
import { Loading, LoadingPage } from '../common/utils';
import { doc, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// TODO: Move this somewhere else
class Profile {
  constructor({user = null, info = null}) {
    this.user = user;
    this.info = info;
  }
  // _update(data) {
  //   this.info = {...data};
  // }
  static Guest() {
    return new Profile({user: null, info: null});
  }
  get isGuest() {
    return !this.user;
  }
  get isLoggedIn() {
    return this.user && this.user.uid;
  }
  get isVerified() {
    return this.isLoggedIn && this.user.verified;
  }
  get exists() {
    return this.isLoggedIn && this.info;
  }
  get isRegistered() {
    return this.exists && this.info.registered;
  }
  get isAdmin() {
    return this.isRegistered && this.info.admin === true;
  }
  get id() {
    return this.user ? this.user.uid : null;
  }
  get uid() {
    return this.id;
  }
  get data() {
    return this.info || {};
  }
  get ref() {
    return doc(db, 'users', this.id);
  }
  get fullname() {
    let data = this.data;
    return [data.first_name, data.last_name].filter((val) => !!val).join(' ')
  }
  get name() {
    let data = this.data;
    return data.display_name || this.fullname;
  }
}

const ProfileContext = createContext(null);
function useProfile() {
  return useContext(ProfileContext);
}
function withLoadingProfile(Component, LoadingComponent = Loading) {
  return (props) => {
    const [profile, loading, error] = useProfile();
    // const loadingPage = opts.loadingElement || <Loading/>;
    if (error) {
      return <>An error occurred</>;
    }
    return loading ? <LoadingComponent/> : <Component profile={profile} {...props}/>
  };
}
function ProfileProvider({children}) {
  // TODO: Get rid of useLoadingValue
  // const {error, loading, value, setError, setValue, reset} = useLoadingValue();
  const [authUser, authLoading, authError] = useAuthState(auth);

  const [profile, setProfile] = useState(Profile.Guest());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // console.log('Profile useEffect', profile);
    setLoading(true);
    if (authError) {
      return setError(authError);
    }
    if (authLoading) {
      return;
    }
    if (!authUser) {
      setProfile(Profile.Guest());
      return setLoading(false);
    }
    // TODO: just change this to; checkProfile or something - don't need to actually return data here since we start listening below
    let unlisten;
    let myProfileFn = httpsCallable(functions, 'myProfile');
    myProfileFn().then((result) => {
      let data = result.data || {};
      let profile = new Profile({user: authUser, info: data.profile});
      // console.log('profile result', profile, result);
      setProfile(profile);
      if (!profile.exists) {
        return;
      }
      unlisten = onSnapshot(doc(db, 'users', profile.id), (snapshot) => {
        // console.log('user snapshot', profile.id, snapshot.data(), snapshot.exists());
        let newProfile = snapshot.exists() ? new Profile({user: authUser, info: snapshot.data()}) : Profile.Guest();
        setProfile(newProfile);
      }, error => setError(error));
    }).catch((err) => setError(err)).finally(() => setLoading(false));

    return () => {
      // setProfile(Profile.Guest());
      return unlisten && unlisten();
    };;
  }, [authUser, authLoading, authError]);

  let result = [profile, loading, error];
  return <ProfileContext.Provider value={result}>{children}</ProfileContext.Provider>;
}

export {
  Profile,
  useProfile,
  ProfileProvider,
  withLoadingProfile
};