import React, { useState, useEffect, useReducer } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, orderBy } from 'firebase/firestore';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

import ChallengeHeader from '../../components/challenge/ChallengeHeader';
import LogEntryForm from '../../components/challenge/logging/LogEntryForm';

import { db } from '../../common/firebase';
import { ErrorPage, FullscreenLoadingPage, transformFirestoreDates } from '../../common/utils';
import moment from 'moment';
import ProgressChart from '../../components/challenge/progress/ProgressChart';
import RecentLogActivity from '../../components/challenge/activity/RecentLogActivity';
import TeamLeaderboard from '../../components/challenge/teams/TeamLeaderboard';

import useChallengeEntry from '../../hooks/challenge/useChallengeEntry';
import useChallengeTeam from '../../hooks/challenge/useChallengeTeam';

function LogDaySelect({ logDay, disabled, challenge, onChange }) {
  //
  return (
    <select name="entry_day" value={logDay} disabled={disabled || challenge.isFirstDay() || challenge.isFinalLogDay()} onChange={(event) => onChange(event.target.value)} className="form-select bg-white rounded-lg py-1 border-none font-extrabold">
      <option value="today">Today</option>
      <option value="yesterday">Yesterday</option>
    </select>
  )
}

function LogEntryBlock({challenge, profile, signupData, logDay, setLogDay}) {
  //
  const loggingToday = logDay !== 'yesterday';
  const logForDate = loggingToday ? moment() : moment().subtract(1, 'day');
  const dayKey = logForDate.format('YYYYMMDD');
  const userEntryPath = `users/${profile.id}/user_challenges/${challenge.key}/user_entries/${dayKey}`;
  const challengeEntryPath = `challenges/${challenge.key}/challenge_entries/${dayKey}-${profile.id}`;
  const entry = useChallengeEntry({profile, team: signupData.team, userEntryPath, challengeEntryPath});

  const saveEntry = function() {
    return entry.save(logForDate.toDate());
  };

  const disabled = entry.saving || entry.loading || challenge.hasEnded();
  let btnText = entry.loading ? 'Loading...' : (entry.saving ? 'Saving...' : (loggingToday ? 'Log for Today' : 'Log for Yesterday'));
  let lastUpdate = !entry.saving && entry.value.last_update ? moment(entry.value.last_update.toDate()) : null;
  // maybe put "saving/loading" in the box - then add a "Saved" with a timeout? or better, show the last_updated date (which would be empty before save)
  // TODO: disable/change button text to "Saving..." if entry.saving
  return (
    <div className="log-entry flex flex-col">
      <div className="challenge-card flex flex-col grow justify-between">
        <div className="log-title flex justify-between items-center mb-4">
          <div><LogDaySelect logDay={logDay} disabled={disabled} challenge={challenge} onChange={setLogDay}/></div>
          <div className="text-gray-500 font-bold">{entry.countChecked()}/4 Completed</div>
        </div>
        {/* <div className="text-2xl font-extrabold">debug: {challenge.canLogForDay(logForDate)}</div> */}
        <LogEntryForm logForDate={logForDate} entry={entry.value} profile={profile} team={signupData.team} disabled={disabled} toggleItem={entry.toggleItem} setComment={entry.setComment} setBonusPoints={entry.setBonusPoints}/>
        <div className=" text-xs text-gray-500 italic text-center mt-1">{lastUpdate && <>Saved {lastUpdate.format(loggingToday ? 'h:mm a' : 'MM/DD h:mm a')}</>}&nbsp;</div>
      </div>
      <div className="pt-4 shrink">
        <button onClick={saveEntry} className="btn-fit bg-black text-white font-extrabold shadow-md hover:shadow-lg" disabled={disabled}>{!disabled && <FontAwesomeIcon icon={faPlusCircle} size="lg"/>} {btnText}</button>
      </div>
    </div>
  );
}

export default function ChallengeView() {
  const {challenge, profile, signup, challengeUser} = useOutletContext();
  const [logDay, setLogDay] = useState('today');
  let {team, loading, error} = useChallengeTeam({challengeId: challenge.key, teamId: signup.team && signup.team.id});

  if (error) {
    console.error('team error', error);
    return <ErrorPage message="Could not load team"/>
  }
  if (loading) {
    return <FullscreenLoadingPage/>
  }
  const funNames = {
    'JsmhA3DwChfMFRKvX8Pscd7b8KL2': 'Mix Nasty',
    'ArXnXilxjZUyftDYo8FTDAyMQ9x1': 'Brod\'s',
    'U2sWPKroRJdpdARiQJS0UYOfkKt2': 'The Zollo (Favorite Zollo)',
    'Sk1Uktf9M2Pws0R25kJDqrvAIAC3': 'Rising Star',
    'FB0qiyuLKReFFD60OxYge8oAiqn2': 'Nate Dog',
    '8s1gPW6LQPUsxxCTydZe3MAA8Wu2': 'Bern',
    'rArQaJ3RIWZGSux5iDcZwJfd6if1': 'J2',
    'cYuI212aJ9esVImILMjmAqkofgF3': 'Mikey'
  };
  const formatName = function(profile) {
    const name = profile.name
    return funNames[profile.id] || name;
  };
  const myActivity = challengeUser.activity || [];
  const pointsBreakdown = myActivity.reduce((breakdown, entry) => {
    ['faith', 'food', 'fitness', 'fuel', 'bonus_points'].forEach(item => {
      if (entry[item]) {
        breakdown[item]++;
      }
    });
    return breakdown;
  }, {faith: 0, food: 0, fitness: 0, fuel: 0, bonus_points: 0});
  const userEntriesRef = collection(db, 'users', profile.id, 'user_challenges', challenge.key, 'user_entries');
  let currentLogDay = challenge.isFinalLogDay() ? 'yesterday' : logDay;
  return (
    <div>
      <div className="mt-3">
        <div className="text-gray-400 font-extrabold">{moment().format('ddd, MMM D')}</div>
        <div className="text-2xl font-extrabold">Hi, {formatName(profile)}</div>
      </div>
      <ChallengeHeader challenge={challenge}/>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-4">
        {/*  TODO: should we disable entry if the user doesnt' have a team? */}
        <LogEntryBlock challenge={challenge} profile={profile} logDay={currentLogDay} setLogDay={setLogDay} signupData={signup}/>
        <div className="challenge-card">
          <div className="font-extrabold text-xl">My Progress</div>
          <div className="text-md font-light my-1">{signup.points || 0} points {team && 'for the ' + team.name + ' team'}</div>
          <ProgressChart points={signup.points} entriesRef={userEntriesRef} challenge={challenge}/>
        </div>
        <div className="challenge-card md:col-span-2">
          <div className="font-extrabold text-xl">My Points</div>
          <div className="flex justify-around text-center py-1">
              <div>
                <div className="text-lg font-bold">{pointsBreakdown.faith}</div>
                <div className="text-md font-light">Faith</div>
              </div>
              <div>
                <div className="text-lg font-bold">{pointsBreakdown.food}</div>
                <div className="text-md font-light">Food</div>
              </div>
              <div>
                <div className="text-lg font-bold">{pointsBreakdown.fitness}</div>
                <div className="text-md font-light">Fitness</div>
              </div>
              <div>
                <div className="text-lg font-bold">{pointsBreakdown.fuel}</div>
                <div className="text-md font-light">Fuel</div>
              </div>
              <div>
                <div className="text-lg font-bold">{pointsBreakdown.bonus_points}</div>
                <div className="text-md font-light">Bonus</div>
              </div>
            </div>
        </div>
        <div className="challenge-card md:col-span-2">
          <div className="font-extrabold text-xl mb-2">Leaderboard</div>
          <TeamLeaderboard challenge={challenge} profile={profile}/>
        </div>
        <div className="challenge-card md:col-span-2">
          <div className="flex justify-between mb-4">
            <div className="font-extrabold text-xl">Activity</div>
            {/* <div className=""><button className="rounded-xl shadow-sm py-2 px-4 bg-white font-extrabold">View All</button></div> */}
          </div>
          <RecentLogActivity challenge={challenge}/>
        </div>
      </div>
      {/* <div className="text-right"><Link to="/user/logout">Logout</Link></div> */}
    </div>
  );
}
