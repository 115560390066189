import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faTint, faBible, faRunning } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

function LogEntryItem({label, icon, disabled, isActive, onClick}) {
  //
  return (
    <div className={"flex flex-col items-center cursor-pointer" + (disabled ? ' opacity-50' : '')} onClick={onClick}>
      <div className={'fit-log-icon flex flex-col justify-center items-center w-12 h-12 rounded-full' + (isActive ? ' bg-primary orange-glow text-white' : ' bg-gray-300 text-black')}>
        <FontAwesomeIcon icon={icon} size="lg"/>
      </div>
      <div className="font-extrabold">{label}</div>
    </div>
  );
}
function LogEntryComment({value, disabled, maxLength, onChange}) {
  //
  return (
    <>
      <textarea name="entry_comment" maxLength={maxLength} value={value} disabled={disabled} onChange={(event) => onChange(event.target.value)} rows="4" className="form-textarea w-full border-gray-300 rounded-lg py-1 px-2 text-sm"></textarea>
      <div className="text-xs text-gray-300 text-right font-light">{value.length}/{maxLength}</div>
    </>
  );
}
//
export default function LogEntryForm({ entry, disabled, logForDate, toggleItem, setComment, setBonusPoints }) {
  // const [bonusPoint, setBonusPoint] = useState(false);
  const entryItems = [
    {key: 'faith', label: 'Faith', icon: faBible},
    {key: 'food', label: 'Food', icon: faAppleAlt},
    {key: 'fitness', label: 'Fitness', icon: faRunning},
    {key: 'fuel', label: 'Fuel', icon: faTint}
  ];
  // console.log('comment', entry.comment)
  const dayIsTuesday = 2 === logForDate.day();
  // const todayIsTuesday = 1 === moment().day();
  return (
    <>
      <div className="flex justify-around my-4">
        {entryItems.map(item => <LogEntryItem key={item.key} disabled={disabled} onClick={() => toggleItem(item.key)} isActive={entry[item.key]} {...item}/>)}
      </div>
      {dayIsTuesday && <div className="my-2"><label>T-Shirt bonus point <input type="checkbox" className="ml-2" name="bonus_point" checked={entry.bonus_points > 0} onChange={(e) => setBonusPoints(e.target.checked ? 1 : 0)}/></label></div>}
      <div><LogEntryComment value={entry.comment} disabled={disabled} maxLength={250} onChange={setComment}/></div>
    </>
  );
}

