import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faTint, faBible, faRunning } from '@fortawesome/pro-regular-svg-icons';

const limitLines = function(str = '', limit = 4) {
  const lines = str.split('\n');
  return lines.length < limit ? str : lines.slice(0,limit-1).concat(lines.slice(limit-1).join(' ')).join('\n')
};
const limitLength = function(str = '', limit = 250) {
  return str.length > limit ? str.substring(0, limit) : str;
};
export default function LogActivityEntry({entry, team}) {
  //
  const maxLines = 4;
  const maxLength = 250;
  const comment = limitLength(limitLines(entry.comment || '', maxLines), maxLength);
  return (
    <div className="mb-2 border-b-2">
      <div className="text-sm flex justify-between">
        <div><span className="font-bold">{entry.user_name}</span> &nbsp;<span className="text-gray-500">{team && team.name}</span></div>
        <div className="text-gray-500">{moment(entry.last_update).fromNow()}</div>
      </div>
      <div className="whitespace-pre-line">{comment}</div>
      <div className="flex justify-between items-center">
        <div className="flex gap-4 py-2">
          {entry.faith && <FontAwesomeIcon icon={faBible} size="sm"/>}
          {entry.food && <FontAwesomeIcon icon={faAppleAlt} size="sm"/>}
          {entry.fitness && <FontAwesomeIcon icon={faRunning} size="sm"/>}
          {entry.fuel && <FontAwesomeIcon icon={faTint} size="sm"/>}
        </div>
        <div className="text-xs text-gray-500">{moment(entry.entry_date).format('MM/DD')}</div>
      </div>
    </div>
  );
}
