import React, { useState, useEffect } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';

import ChallengeFullView from './ChallengeFullView';
import ChallengeHeader from '../../components/challenge/ChallengeHeader';

import moment from 'moment';

function CountdownTimer({toDate, onEnd}) {
  // TODO: add image_url to challenge settings

  const getTimeLeft = function() {
    let now = new Date();
    let difference = +toDate - +now;

    let timeLeft = difference > 0 ? {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        ended: false
    } : {ended: true};
    return timeLeft;
  }
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());
  let hasPassed = timeLeft.ended === true;
  useEffect(() => {
    if (hasPassed) {
      return;
    }
    const timer = setTimeout(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (hasPassed) {
      onEnd()
    }
  }, [hasPassed]);

  return (
    <>
    <div className="flex justify-center">
      <div className="px-4">
        <div className="text-xl font-extrabold">{timeLeft.days || 0}</div>
        <div className="text-s font-light">Day{timeLeft.days !== 1 && 's'}</div>
      </div>
      <div className="px-4">
        <div className="text-xl font-extrabold">{timeLeft.hours || 0}</div>
        <div className="text-s font-light">Hour{timeLeft.hours !== 1 && 's'}</div>
      </div>
      <div className="px-4">
        <div className="text-xl font-extrabold">{timeLeft.minutes || 0}</div>
        <div className="text-s font-light">Minute{timeLeft.minutes !== 1 && 's'}</div>
      </div>
      <div className="px-4">
        <div className="text-xl font-extrabold">{timeLeft.seconds || 0}</div>
        <div className="text-s font-light">Second{timeLeft.seconds !== 1 && 's'}</div>
      </div>
    </div>
    </>
  )
}

export default function ChallengeView() {
  const {challenge, profile, signup} = useOutletContext();
  const [countdownEnded, setCountdownEnded] = useState(false);

  // TODO: remove after testing
  const [searchParams, ] = useSearchParams();
  const testMode = searchParams.get('test');
  if (testMode) {
    return <ChallengeFullView/>;
  }
  if (challenge.isActive()) {
    return <ChallengeFullView/>;
  }
  // TODO: rename this to ChallengePendingView
  return (
    <div>
      <div className="mt-3">
        <div className="text-gray-400 font-extrabold">{moment().format('ddd, MMM D')}</div>
        <div className="text-2xl font-extrabold">Hi, {profile.name}</div>
      </div>
      <ChallengeHeader challenge={challenge}/>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-4">
        <div className="rounded-lg bg-gray-100 p-4">
          <div className="text-2xl font-bold">Old School vs. New School</div>
          <div className="mt-2 text-sm">Congrats! You're registered to participate in this fitness challenge.</div>
          <div className="text-sm">Add this site to your home screen. All challenge logging will take place here starting on 2/22!</div>
          <div><Link to="rules" className="text-sm">Read the Rules</Link></div>
          <div className="text-sm"><a href="https://forms.gle/9TQFF6BPLpozC3ri8" rel="noreferrer" target="_blank">Request a MYZONE Heart Rate Monitor</a></div>
        </div>
        <div className="rounded-lg bg-gray-100 p-4 text-center">
          <div>The Challenge begins <span className="font-bold">{moment(challenge.start_date).format('dddd, M/D/YYYY')}</span></div>
          <div className="py-8 font-extrabold">
            <CountdownTimer toDate={challenge.start_date} onEnd={() => setCountdownEnded(true)}/>
          </div>
        </div>
      </div>
      {/* <div className="text-right"><Link to="/user/logout">Logout</Link></div> */}
    </div>
  );
}
