import React, { useState, useEffect } from "react";

import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import { FullscreenLoadingPage, ErrorPage } from '../common/utils';
import { withLoadingProfile, useProfile } from '../providers/profile.provider';

import Header from './layout/Header';

function Layout() {
  const [profile, loading, error] = useProfile();
  const isDesktop = useMediaQuery({query: '(min-width: 1024px)'});
  //
  if (error) {
    // TODO: error page
    return <ErrorPage message="Could not load user info"/>;
  }
  if (loading) {
    return <FullscreenLoadingPage/>;
  }
  const context = {
    profile,
    isDesktop
  };
  let bgStyle = profile.isLoggedIn ? 'bg-white text-black' : 'bg-black text-white';
  return (
    <>
      {profile.isLoggedIn && <Header profile={profile}/>}
      <div className={'w-full h-full ' + bgStyle}>
        <main className="container mx-auto">
          <Outlet context={context}/>
        </main>
      </div>
      {profile.isLoggedIn && <footer><div className="text-center mt-4 mb-1 text-xs">&copy; 2022 Elevation Church</div></footer>}
    </>
  )
}

export default Layout;
