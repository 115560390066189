import React, { useState, useEffect, useReducer } from 'react';

import { updateDoc } from 'firebase/firestore';
import { useDocument, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { Loading, ErrorPage, transformFirestoreDates } from '../../common/utils';

import moment from 'moment';

export default function RegistrationForm({ profile, onConfirm }) {
  //
  // we don't need a reducer. just useState for shirt/display name
  const [data, dispatch] = useReducer(registerReducer, {
    display_name: '',
    shirt_size: '',
  });
  // const [displayName, setDisplayName] = useState('');
  // const [shirtSize, setShirtSize] = useState('Large');
  // TODO: why? just getDoc inside of useEffect
  const [docData, loading, error] = useDocumentDataOnce(profile.ref, {transform: transformFirestoreDates});
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (!loading) {
      dispatch({type: 'init', data: docData})
      // setShirtSize(docData.shirt_size || 'Large');
      // setDisplayName(docData.display_name || docData.first_name);
    }
  }, [loading]);

  if (loading) {
    return <Loading/>;
  }

  if (error) {
    console.error(error);
    return <ErrorPage/>;
  }
  // return <ErrorPage message="Registration has closed"/>;
  const handleChange = function(e) {
    dispatch(e);
    // data[e.target.name] = e.target.value;
  };
  const handleSubmit = function(e) {
    e.preventDefault()
    // TODO: validate
    console.log('submit', e);
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const updateData = {
      display_name: data.display_name,
      shirt_size: data.shirt_size,
      registered: true,
    };
    updateDoc(profile.ref, updateData).then(() => {
      console.log('doc updated');
      onConfirm();
    }).catch(err => {
      console.log('error', err);
      setSubmitting(false)
    });
  };
  // TODO: hire date?
  // shirt size: Small, Medium, Large, XL, XXL, XXXL
  const shirtSize = data.shirt_size || 'Large';
  return (
    <form name="registerForm" onSubmit={handleSubmit} disabled={submitting}>
      <center>{loading && <Loading/>}</center>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-4">
        <div><span className="text-lg font-bold">{docData.first_name}</span><span className="block border-t-2 text-xs font-light">First Name</span></div>
        <div><span className="text-lg font-bold">{docData.last_name}</span><span className="block border-t-2 text-xs font-light">Last Name</span></div>
        <div><span className="text-lg font-bold">{docData.email}</span><span className="block border-t-2 text-xs font-light">Email Address</span></div>
        <div><span className="text-lg font-bold">{docData.phone}</span><span className="block border-t-2 text-xs font-light">Phone Number</span></div>
        <div><span className="text-lg font-bold">{moment(docData.birth_date).format('MM-DD-YYYY')}</span><span className="block border-t-2 text-xs font-light">Birth Date</span></div>
        <div><span className="text-lg font-bold">{docData.gender}</span><span className="block border-t-2 text-xs font-light">Gender</span></div>
        <label><input type="text" name="display_name" value={data.display_name} onChange={handleChange} required className="form-input w-full rounded-lg border-gray-300"/><span className="block p-1 text-xs font-light">Display Name</span></label>
        <label><TShirtSelect value={shirtSize} onChange={handleChange} required/><span className="block p-1 text-xs font-light">Shirt Size</span></label>

      </div>
      <div className="mx-auto lg:w-1/2"><button type="submit" className="btn-primary">Confirm</button></div>
    </form>
  );
}

const registrationValid = function(data) {
  return true;
};

const TShirtSelect = function({value = 'Large', onChange, ...params}) {
  const options = ['Small', 'Medium', 'Large', 'XL', 'XXL', 'XXXL'];
  return (
    <select name="shirt_size" onChange={onChange} value={value} className="form-select w-full rounded-lg border-gray-300" {...params}>
      {options.map((option) => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
  )
}

const registerReducer = (state, event) => {
  if (event.type === 'init') {
    let data = event.data || {};
    return {
      ...state,
      ...data
    }
  }
  return {
    ...state,
    [event.target.name]: event.target.value
  };
};


