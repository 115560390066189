
import React, { useState, useEffect } from "react";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { getUserTeam } from "../../api/client";


export default function Nav({ profile }) {
  
  const navigate = useNavigate();
  // TODO: My Challenges link - the "My Team" link should only show on a specific challenge
  // const params = useParams();
  // const location = useLocation();
  // const [team, setTeam] = useState(null);
  // const challengeId = params.challengeKey || null;
  // const userId = profile.id;
  // console.log('params', location);
  // useEffect(() => {
  //   if (!challengeId) {
  //     return;
  //   }
  //   getUserTeam({userId, challengeId}).then((result) => {
  //     setTeam(result);
  //   })
  // }, [userId, challengeId]);

  return (
    <Menu align="end" arrow menuButton={<MenuButton><div className="mx-4 px-2 py-1 bg-secondary rounded-full hover:opacity-70"><FontAwesomeIcon icon={faUser}/></div></MenuButton>}>
      {/* <MenuItem onClick={() => navigate('/user/profile')}>Profile</MenuItem> */}
      {/* <MenuItem>My Team</MenuItem> */}
      {/* {team && <MenuItem onClick={() => navigate('/challenges/' + challengeId + '/teams/' + team.$id)}>My Team</MenuItem>} */}
      <MenuItem onClick={() => navigate('/challenges/oldvsnew/rules')}>Challenge Rules</MenuItem>
      <MenuItem onClick={() => navigate('/user/logout')}>Logout</MenuItem>
      {/* <MenuItem onClick={() => navigate('/challenges/oldvsnew', {state: {log: true}})}>Log points</MenuItem> */}
    </Menu>
  );
}

