import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, ReferenceLine, ReferenceArea, Tooltip, Label, LabelList, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

import moment from 'moment';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { transformFirestoreDates } from '../../../common/utils';

const CustomTooltip = ({points}) => {
  // console.log('custom label', points);
  const today = moment();
  return (
    <div className="bg-black rounded-xl text-white text-center font-bold py-1 flex justify-between">
      <div className="py-1 px-2 border-r border-gray-500">{today.format('MMM D')}</div>
      <div className="py-1 px-2">{points} pts</div>
    </div>
  );
};

const calcDayPoints = (entry) => {
  return [entry.faith, entry.food, entry.fitness, entry.fuel].filter(v => v).length + (entry.bonus_points || 0);
}

// TODO: rename ProgressChart (the block is defined in the view)
export default function ProgressChart({ entriesRef, points, challenge }) {
  const [mouseOver, setMouseOver] = useState(false);
  // first point = 0, possible = 0;
  // challenge start + 7
  // total data is.. 7*4 possible per week (highest point)
  // 5*4 minimum per week (this is the reference line)
  // 168 total possible (28 per week)
  // TODO: load actual data (either have to map/reduce user entries ... or figure something out by dates)
  // 2022/2/15 + 7
  const [entryDocs, loading, error] = useCollectionData(entriesRef, {transform: transformFirestoreDates});


  if (error) {
    console.error('progress error', error);
  }

  const entries = entryDocs || [];
  const today = moment();
  let startOf = moment(challenge.start_date || '2022-02-10');
  // let startOf = moment('2022-02-10');
  let endOf = moment(challenge.end_date || '2022-04-14');

  // TODO: use effect
  // console.log('entries', entries);
  let weeklyData = [
    {week: 0, needed: 0, points: 0, total_points: 0}
  ];
  let totalPointsToDate = 0;
  let neededWeeklyPoints = 20;
  let currentWeekStart = moment(startOf);
  let currentWeekEnd = moment(startOf).add(1, 'week');
  let currentWeekIndex = 0;
  // TODO: base this off of challenge start/end instead of static 6 weeks
  for (let week = 1; week <= 6; week++) {
    let needed = week * neededWeeklyPoints;
    const weekStart = currentWeekStart;
    const weekEnd = currentWeekEnd;
    // let weekData = {week, needed, start: weekStart.toDate(), end: weekEnd.toDate()}
    let weekData = {week, needed}
    let matchingEntries = entries.filter((e) => {
      return e && e.entry_date && moment(e.entry_date).isBetween(weekStart, weekEnd, undefined, '[)');
    });
    let weeklyPoints = matchingEntries.map((e) => e.points || 0).reduce((weeklyPoints, entryPoints) => weeklyPoints + entryPoints, 0);
    // let pointsPerCategory = matchingEntries.reduce((result, entry) => {
    //   result.faith += entry.faith ? 1 : 0;
    //   result.food += entry.food ? 1 : 0;
    //   result.fitness += entry.fitness ? 1 : 0;
    //   result.fuel += entry.fuel ? 1 : 0;
    //   return result;
    // }, {faith: 0 , food: 0, fitness: 0, fuel: 0});
    totalPointsToDate += weeklyPoints;
    if (today.isSameOrAfter(currentWeekStart)) {
      weekData.points = weeklyPoints;
      weekData.total_points = totalPointsToDate;
      currentWeekIndex = week;
    }
    weeklyData.push(weekData);
    currentWeekStart = moment(weekEnd);
    currentWeekEnd = moment(currentWeekStart).add(1, 'week');
  }
  // console.log('weekly data', weeklyData);
  const maxPoints = 168;
  return (
    <div className="text-xs" onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={weeklyData} margin={{right: -10, bottom: 10}}>
          {/* <CartesianGrid stroke="#ccc" verticalPoints={[0, 50, 150, 250]} horizontalPoints={[1, 100, 200, 300]}/> */}
          <CartesianGrid stroke="#ccc" verticalPoints={[150]}/>
          <YAxis allowDataOverflow yAxisId="right" orientation="right" domain={[0, 168]} ticks={[0, 50, 120, 168]} scale="auto" strokeWidth={0}/>
          <XAxis dataKey="week" scale="auto" strokeWidth={0} >
            <Label value="Week" offset={-5} position="insideBottom" fill="grey"/>
          </XAxis>
          <ReferenceLine yAxisId="right" stroke="lightgrey" strokeWidth={6} segment={[{ x: 0, y: 0 }, { x: 6, y: 120 }]} />
          {/* <LabelList dataKey="week" position="insideTop"  /> */}
          <Line type="monotone" dataKey="total_points" stroke="#FF671F" strokeWidth={6} yAxisId="right"/>
          {mouseOver
            ? <Tooltip labelFormatter={(value, name, props) => value === 0 ? 'Start of challenge' : `Week ${value}`} formatter={(value, name, props) => [value, 'Total points']}/>
            : <Tooltip content={<CustomTooltip points={totalPointsToDate}/>} cursor={false} isAnimationActive={false} wrapperStyle={{ visibility: loading ? 'hidden' : 'visible' }} position={{ x: currentWeekIndex, y: maxPoints - totalPointsToDate }} />
          }
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

