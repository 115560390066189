import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useOutletContext } from 'react-router-dom';

import LoginForm from '../../../components/user/LoginForm';

import fitIconBlack from '../../../static/img/fit-logo-black.png';
import fitIconWhite from '../../../static/img/fit-logo-white.png';
import splash from '../../../static/img/login-splash-desktop.png';
import { heroImages } from '../../../static/img/login';
import styles from './Login.module.css';
import './login.css';

function LoginView() {
  //
  const navigate = useNavigate();
  const {profile, isDesktop} = useOutletContext();

  if (profile.isLoggedIn) {
    console.log('login user, redirecting');
    return <Navigate to="../register" replace/>;
  }

  const handleError = function(err) {
    // TODO: error handling
    console.error('login error', err);
  };

  const handleLogin = function() {
    // console.log('success! logged in');
    navigate('../register', {replace: true});
  };

  // TODO: add an "onError" param to LoginForm, display/handle errors here, not in the form */
  //  useState() and pass setError as param to form?
  // or pass handleError fn as param to form?

  const LoginComponent = isDesktop ? DesktopLogin : MobileLogin;
  return <LoginComponent handleLogin={handleLogin} handleError={handleError}/>
}

function DesktopLogin({ handleLogin, handleError }) {
  return (
    <div className={"flex justify-center content-center text-white min-h-screen " + styles.container}>
      <div className="flex flex-col justify-center items-center px-6">
        <div className="mb-2"><img src={fitIconWhite} alt="Elevation Fit"/></div>
        <LoginForm onLogin={handleLogin} onError={handleError}/>
        {/* TODO: error display component */}
      </div>
      <div><img src={splash} alt="That you are as healthy in body as you are in spirit."/></div>
    </div>
  );
}

function MobileLogin({ handleLogin, handleError }) {
  const heroText = ['Set wellness goals', 'Track your progress', 'Work as a team'];
  return (
    <div className="flex flex-col justify-around items-center text-black bg-secondary min-h-screen">
      <div className="pt-6"><img src={fitIconBlack} alt="Elevation Fit"/></div>
      <div className={styles.heroSlider}><HeroSlider images={heroImages} text={heroText}/></div>
      <div className="flex flex-col justify-center items-center px-6 mt-4">
        <LoginForm onLogin={handleLogin} onError={handleError}/>
      </div>
    </div>
  );
}
function HeroSlider({images = [], text = []}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const maxIndex = images.length - 1;

  // TODO: fade / slide
  const nextSlide = useCallback(() => {
      let nextIndex = (currentIndex >= maxIndex) ? 0 : currentIndex + 1;
      setCurrentIndex(nextIndex);
  }, [currentIndex, maxIndex]);

  useEffect(() => {
    let timeout = setTimeout(() => nextSlide(), 3500);
    return () => clearTimeout(timeout);
  }, [nextSlide]);
  //
  return (
    <>
      <div onClick={() => nextSlide()}><img src={images[currentIndex]} alt="Elevation Fit"/></div>
      <div className="text-base leading-6 text-center font-medium mt-4 mb-6">{text[currentIndex]}</div>
      <div className="flex justify-center">
        {images.map((img, index) => {
          return <div key={index} className={currentIndex === index ? 'slide-rectangle active' : 'slide-rectangle'}></div>
        })}
      </div>
    </>
  )
}

export default LoginView;
