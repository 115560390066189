import React, { useState, useEffect, useReducer } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, orderBy } from 'firebase/firestore';
import { transformFirestoreDates } from '../../common/utils';
import { db } from '../../common/firebase';

const defaultEntry = {food: false, faith: false, fuel: false, fitness: false, comment: ''};
const calcPoints = ({food = false, faith = false, fuel = false, fitness = false, bonus_points = 0}) => {
  return [food, faith, fuel, fitness].filter(val => val).length + (bonus_points || 0);
};
const entryReducer = (state, action) => {
  // console.log('entry reducer, action', action);
  switch(action.type) {
    case 'item/toggle':
      const itemKey = action.item;
      let newState = {...state, [itemKey]: !state[itemKey]};
      newState.points = calcPoints(newState);
      return newState;
    case 'item/bonus':
        return {...state, bonus_points: action.value};
    case 'comment/update':
      return {...state, comment: action.value};
    case 'db/snapshot':
      return {...state, ...action.data};
    case 'reset':
      return defaultEntry;
    default:
      return state;
  }
};

export default function useChallengeEntry({profile, team, userEntryPath, challengeEntryPath}) {
    const [value, dispatch] = useReducer(entryReducer, defaultEntry);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
      setLoading(true);
      dispatch({type: 'reset'});
      const docRef = doc(db, userEntryPath);
      const unlisten = onSnapshot(docRef, (snap) => {
        const data = snap.exists ? snap.data() : defaultEntry;
        // console.log('got entry', docPath, snap.data());
        dispatch({type: 'db/snapshot', data})
        setLoading(false);
      }, (err) => setError(err));
      return unlisten;
    }, [userEntryPath, challengeEntryPath]);

    const saveEntry = function(logEntryDate) {
      // TODO: move to api/challenges - and save both doc paths
      // TODO: do we need to check for today/yesterday? will firestore rules catch that?
      setSaving(true);
      const today = new Date();
      const userEntryRef = doc(db, userEntryPath);
      const challengeEntryRef = doc(db, challengeEntryPath);
      // const docData = {...value, last_update: today};
      const updateData = {
        faith: value.faith,
        fuel: value.fuel,
        fitness: value.fitness,
        food: value.food,
        comment: value.comment,
        last_update: today,
        team: team || null,
        user: profile.ref,
        bonus_points: value.bonus_points || 0,
        entry_date: value.entry_date || logEntryDate || today
      };
      const challengeUpdateData = {...updateData, entry: userEntryRef, user_name: profile.name};
      // console.log('saving challenge data', challengeUpdateData);
      return runTransaction(db, async (t) => {
        // await t.get(userEntryRef);
        let userSetPromise = t.set(userEntryRef, updateData, {merge: true});
        let challengeSetPromise = t.set(challengeEntryRef, challengeUpdateData, {merge: true});
        return Promise.all([userSetPromise, challengeSetPromise]);
      }).catch((err) => setError(err)).finally(() => setSaving(false));

    }

    const countChecked = function() {
      return [value.faith, value.food, value.fitness, value.fuel].filter(v => v).length;
    }
    const calcPoints = function() {
      return countChecked() + (value.bonus_points || 0);
    }

    // TODO: reload?
    return {
      value,
      loading,
      saving,
      error,
      calcPoints,
      countChecked,
      toggleItem: (key) => {
        dispatch({type: 'item/toggle', item: key});
      },
      setBonusPoints: (bonusPoints) => {
        dispatch({type: 'item/bonus', value: bonusPoints});
      },
      setComment: (text) => {
        dispatch({type: 'comment/update', value: text})
      },
      save: (logEntryDate) => {
        return saveEntry(logEntryDate);
      }
    }
  }
