import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

import { doc, collection } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { FullscreenLoadingPage, ErrorPage, transformFirestoreDates } from '../../common/utils';
import useChallenge from '../../hooks/challenge/useChallenge';
import { db } from '../../common/firebase';
import useChallengeSignup from '../../hooks/challenge/useChallengeSignup';
import useChallengeUser from '../../hooks/challenge/useChallengeUser';

export default function ChallengeLayout() {
  // TODO: load challenge here. useOutletContext in child-routes
  const { challengeKey } = useParams();
  const { profile } = useOutletContext();

  // const challengeRef = doc(db, 'challenges', challengeKey);
  // const [challenge, loading, error] = useDocumentData(challengeRef, {transform: transformFirestoreDates});
  const challenge = useChallenge(challengeKey);
  const signup = useChallengeSignup({challengeId: challengeKey, userId: profile.id});
  const challengeUser = useChallengeUser({challengeId: challengeKey, userId: profile.id});

  // TODO: do we also need to load the user's "challengeProfile" here? .. or at least build the ref
  if (challenge.error) {
    console.error('challenge error', challenge.error);
    return <ErrorPage message="Unable to load Challenge details"/>;
  }
  if (signup.error) {
    console.error('challenge error', signup.error);
    return <ErrorPage message="Unable to load Signup details"/>;
  }
  if (challenge.loading || signup.loading) {
    return <FullscreenLoadingPage/>;
  }
  //
  const context = {
    signup,
    challenge,
    profile,
    challengeUser
  };
  return (
    <div className="px-4 md:px-0 max-w-4xl mx-auto">
      <Outlet context={context}/>
    </div>
  );
}

