import React, { useState, useEffect, useReducer } from 'react';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams, useOutletContext } from 'react-router-dom';
import { db } from '../../../common/firebase';
import { LoadingPage } from '../../../common/utils';

function ProfileLayout() {
  // 
  const { profile } = useOutletContext();
  const context = {profile};
  return (
    <div>
      <h3>User Profile: {profile.name}</h3>
      <Outlet context={context}/>
    </div>
  )
}

export default ProfileLayout;
