import React, { useState, useEffect } from 'react';

import { collection, doc, getDocs, getDoc, query, limit, orderBy } from 'firebase/firestore';
import { useCollectionData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { db } from '../../../common/firebase';
import { Loading, transformFirestoreDates } from '../../../common/utils';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppleAlt, faTint, faBible, faRunning } from '@fortawesome/pro-regular-svg-icons';

import LogActivityEntry from './LogActivityEntry';
import { useNavigate } from 'react-router-dom';

export default function RecentLogActivity({challenge}) {
  let entriesRef = collection(db, 'challenges', challenge.key, 'challenge_entries');
  // TODO: filter where('last_update', '>=' moment().startOf('day').toDate()), limit(350))
  let entryQuery = query(entriesRef, orderBy('last_update', 'desc'), limit(150));
  let teamsQuery = collection(db, 'challenges', challenge.key, 'challenge_teams');
  const [entries, loading, error] = useCollectionData(entryQuery, {transform: transformFirestoreDates, idField: '$id'});
  const [teams, , ] = useCollectionData(teamsQuery, {transform: transformFirestoreDates, idField: '$id'});
  const navigate = useNavigate();
  // console.log('entries', entries && entries.length);
  // useEffect to get users ? (each entry, group user ids)
  if (error) {
    console.error('recent activity error');
  }
  const getTeam = function(entryTeam) {
    // move this into logactivityitem
    return teams && entryTeam ? teams.find((team) => team.$id === entryTeam.id) : {};
  };
  return (
    <div>
      {/* {loading && <Loading/>} */}
      {entries && entries.map(entry => {
        return <div key={entry.$id} className="cursor-pointer" onClick={() => entry.user && navigate('users/' + entry.user.id)}>
          <LogActivityEntry entry={entry} team={getTeam(entry.team)}/>
        </div>
      })}
    </div>
  )
}
