import React, { useState, useEffect } from 'react';

import { Routes, Route, Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router';

import { IMaskInput } from 'react-imask';

import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../../common/firebase';
import { Loading } from '../../common/utils';

export default function LoginForm({ onLogin }) {
  // TODO: why separate? need to disable button while loading.. etc
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [confirmHandler, setConfirmHandler] = useState(null);
  const [loading, setLoading] = useState(false);
  // TODO: add onError to props instead of putting error here.
  const [error, setError] = useState(null);

  const handlePhone = function(phoneNumber) {
    setLoading(true);
    signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier).then((confirmationResult) => {
      setConfirmHandler(confirmationResult)
    }).catch((err) => setError(err)).finally(() => setLoading(false));
  };
  const handleCode = (code) => {
    // console.log('code submit', code);
    if (!confirmHandler) {
      setLoading(false);
      return setError('Please try again');
    }
    setLoading(true);
    confirmHandler.confirm(code).then(() => {
      onLogin()
    }, err => {
      setLoading(false);
      return setError(err);
    });
  };
  const handleCaptcha = (response) => {
    // console.log('recaptcha callback', response);
    // TODO: what is this for?
  };

  useEffect(() => {
    const verifyOpts = {'size': 'invisible', 'callback': handleCaptcha}
    const verifier = new RecaptchaVerifier('captcha-container', verifyOpts, auth);
    setRecaptchaVerifier(verifier);
  }, []);

  return (
    <>
    <div className="flex flex-col w-96 px-4">
      <div>
        <div className="text-lg font-bold">{confirmHandler ? 'Verify your phone number' : 'Sign in with your phone number'}</div>
        <div className="text-sm leading-5 font-medium text-gray-500">{confirmHandler ? 'Enter the verification code that was sent to your phone' : 'Standard text messaging rates from your wireless carrier may apply.'}</div>
      </div>
      <div className="py-6">
        {/* loading ? <Loading style={{height: '50px'}} active={loading}/> :  */}
        {(confirmHandler
          ? <VerifyCodeForm onSubmit={(code) => handleCode(code)} loading={loading}/>
          : <PhoneNumberForm onSubmit={(phone) => handlePhone(phone)} loading={loading}/>
        )}
        {/* {error && <div>Error: {JSON.stringify(error)}</div>} */}
        <div id="captcha-container"></div>
      </div>
    </div>
    </>
  );
}

function VerifyCodeForm({ onSubmit }) {
  const [confirmationCode, setConfirmationCode] = useState("");

  const handleSubmit = function(e) {
    e.preventDefault();
    return onSubmit(confirmationCode);
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} name="verifyForm">
      <input
        type="text"
        name="confirmation_code"
        autoComplete="one-time-code"
        autoFocus
        inputMode="numeric"
        value={confirmationCode}
        onChange={(e) => setConfirmationCode(e.target.value)}
        className="form-input w-full rounded-lg border-gray-300 lg:bg-black lg:text-gray-500 lg:border-gray-700"
      />
      <div className="mt-2"><button type="submit" className="w-full rounded-md bg-primary text-white shadow-sm px-4 py-2">Verify Code</button></div>
    </form>
  )
}

function PhoneNumberForm(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  //
  const handleSubmit = function(e) {
    e.preventDefault();
    return props.onSubmit('+1' + phoneNumber);
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)} name="loginForm" disabled={props.loading}>
      {/* <input type="tel" name="phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></input> */}
      <label className="block"> <span className="hidden lg:block leading-4 font-medium tracking-regular text-xs text-gray-200">Phone Number</span>
        <PhoneInput value={phoneNumber} onAccept={(value) => setPhoneNumber(value)} required disabled={props.loading}/>
      </label>
      <div className="mt-2"><button type="submit" className="w-full rounded-md bg-primary text-white shadow-sm px-4 py-2" id="sign-in-button" disabled={props.loading}>Text Me A Login Code</button></div>
    </form>
  );
}

function PhoneInput(props) {
  return (
    <IMaskInput
      className="form-input w-full rounded-lg border-gray-300 lg:bg-black lg:text-gray-500 lg:border-gray-700"
      mask='+1 (000) 000-0000'
      placeholder="(704) 555-1234"
      unmask={true}
      value={props.value}
      onAccept={props.onAccept}
      name="phone"
      type="tel"
      {...props}>
    </IMaskInput>
  );
}


