import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useOutletContext } from 'react-router-dom';

import { ErrorPage, FullscreenLoadingPage } from '../../common/utils';

import ChallengeList from '../../components/challenge/ChallengeList';

import { db } from '../../common/firebase';
import { joinChallenge, getChallenge, getChallengeProfile } from '../../api/client';
import moment from 'moment';

export default function ChallengeListView() {
  const {profile} = useOutletContext();
  //
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let autoJoinKey = 'oldvsnew';
    setLoading(true);
    // TODO: load challenge list instead (this is just for the first one)
    const signupProfileP = getChallengeProfile(profile.id, autoJoinKey);
    getChallenge(autoJoinKey).then((data) => {
      const isClosed = data && data.signup_deadline && moment(data.signup_deadline.toDate()).isBefore(moment());
      return signupProfileP.then(signupProfile => {
        if (isClosed && !signupProfile) {
          throw new Error('Registration closed');
        }
        return joinChallenge({challengeId: autoJoinKey, profile});
      });
    }).then(() => {
      navigate('/challenges/' +  autoJoinKey, {replace: true});
    }).catch(err => setError(err));
  }, []);

  if (error) {
    return <ErrorPage message="Registration closed"/>
  }
  if (loading) {
    return <FullscreenLoadingPage/>;
  }

  return (
    <div>
      <h3>Fitness Challenges</h3>
      <ChallengeList userId={profile.id}/>
    </div>
  );
}

