import React, { useState, useEffect, useReducer } from 'react';

import { useOutletContext, Link, useNavigate, Navigate } from 'react-router-dom';

import { useDocument } from 'react-firebase-hooks/firestore';
// import { db } from '../common/firebase';

export default function ProfileEditView() {
  //
  const {profile} = useOutletContext();
  const [submitting, setSubmitting] = useState(false);

  //
  // Handle save here? or elsewhere
  // if we have onSave as a prop, then in UserProfile/index.js we could handle the update and redirecting, instead of redirecting here
  return (
    <div>ProfileEdit ... add form here</div>
  )
}
