import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useOutletContext } from 'react-router-dom';

import { db } from '../../../common/firebase';
import { transformFirestoreDates } from '../../../common/utils';
import { collection, query, orderBy, where, getDocs, startAt, endAt, limit } from 'firebase/firestore';
import { useCollectionData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { LoadingPage } from '../../../common/utils';
import moment from 'moment';
import { CSVLink } from 'react-csv';

export default function AdminView() {
  //
  const navigate = useNavigate();
  const {profile, isDesktop} = useOutletContext();
  const [signups, setSignups] = useState([]);
  const [signupUsers, setSignupUsers] = useState([]);
  const [userTotals, setUserTotals] = useState({});

  // const challengeUsersRef = collection(db, 'challenges', 'oldvsnew', 'challenge_users');
  const usersRef = query(collection(db, 'users'), orderBy('hire_date'));
  const [userData, loading, error] = useCollectionData(usersRef, {transform: transformFirestoreDates});
  const pointCategories = ['faith', 'fitness', 'fuel', 'food', 'bonus_points'];

  useEffect(() => {
    // const usersRef = query(collection(db, 'users'), orderBy('hire_date'));
    const signupsRef = query(collection(db, 'challenges', 'oldvsnew', 'challenge_users'));
    const entriesRef = query(collection(db, 'challenges', 'oldvsnew', 'challenge_entries'));
    let signupsData = [];
    let signedUpRefs = [];
    const signedUp = [];
    const totalsByUser = {};
    getDocs(signupsRef).then((snaps) => {
      snaps.forEach(snap => {
        let data = snap.data();
        signupsData.push(data);
        signedUpRefs.push(data.user);
      });
      setSignups(signupsData);
      let lastUser = signedUpRefs[signedUpRefs.length - 1];
      let firstUser = signedUpRefs[0];
      console.log('first user', firstUser.id);
      console.log('last user', lastUser.id);
      const signupUsersRef = query(collection(db, 'users'), orderBy('user_id'), startAt(firstUser.id), endAt(lastUser.id));
      return getDocs(signupUsersRef).then((snaps => {
        console.log('got snaps', snaps.size);
        snaps.forEach((snap) => {
          signedUp.push(transformFirestoreDates(snap.data()))
        })
        setSignupUsers(signedUp);
      }));
    });
    getDocs(entriesRef).then((snaps) => {
      snaps.forEach(snap => {
        let data = snap.data();
        let entryUser = data.user || {};
        if (!entryUser.id) {
          return;
        }
        if (!totalsByUser[entryUser.id]) {
          totalsByUser[entryUser.id] = {total: 0, faith: 0, fuel: 0, fitness: 0, food: 0, bonus_points: 0};
        }
        pointCategories.forEach((key) => {
          if (data[key]) {
            totalsByUser[entryUser.id][key]++;
            totalsByUser[entryUser.id].total++;
          }
        });
      });
      setUserTotals(totalsByUser);
      // console.log('totals by user', totalsByUser);
    })
  }, []);
  if (!profile.isAdmin) {
    return <Navigate to="/challenges/oldvsnew"/>;
  }
  if (loading) {
    return <LoadingPage/>
  }
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  if (signupUsers[0]) {
    // console.log((signupUsers[0].user), signupUsers.length, userData.length);
  }
  const users = userData.filter((u) => u.registered);
  // const names = users.map(u => [u.first_name, u.last_name].join(','));
  // console.log(names.join('\n'));
  // const users = userData;
  let thirds = Math.round(users.length / 3);
  let oldSchool = users.filter(u => moment(u.hire_date).isBefore(moment('2016-01-01')))
  let middleSchool = users.filter(u => moment(u.hire_date).isBetween(moment('2016-01-01'), moment('2019-09-30')))
  let newSchool = users.filter(u => moment(u.hire_date).isAfter(moment('2019-09-30')))
  // let oldSchool = users.slice(0, thirds);
  // let middleSchool = users.slice(thirds, thirds * 2)
  // let newSchool = users.slice(thirds * 2)
  let userSpouse = [...users].sort((a, b) => {
    if (a.salesforce_account < b.salesforce_account) {
      return -1;
    }
    if (a.salesforce_account > b.salesforce_account) {
      return 1
    }
    return 0;
  });
  const getTeamForUser = function(u) {
    if (moment(u.hire_date).isBefore(moment('2016-01-01'))) {
      return 'Old school';
    }
    if (moment(u.hire_date).isBetween(moment('2016-01-01'), moment('2019-09-30'))) {
      return 'Middle school';
    }
    return 'New School';
  }
  const exportKeys = ['user_id','first_name','last_name','email','phone','display_name','hire_date','birth_date','age','gender','shirt_size','staff','spouse','salesforce_id', 'total', 'faith', 'food', 'fitness', 'fuel', 'bonus_points', 'completed'];
  // get entries. reduce to {user: {fit: totalNum, ...}}
  const csvData = [exportKeys].concat(users.map(user => {
    const currentTotals = userTotals[user.user_id] || {};
    let userValidPoints = pointCategories.map((key) => {
      return currentTotals[key] >= 30 ? 30 : currentTotals[key] || 0;
    }).reduce((validPoints, categoryPoints) => validPoints + categoryPoints, 0);
    const userData = {...user, ...currentTotals, completed: userValidPoints >= 120};

    return exportKeys.reduce((result, key) => {
      let val = key in userData ? userData[key] : '';
      if (val && val.toISOString) {
        val = val.toISOString()
      }
      result.push(val);
      return result;
    }, [])
  }));
  return (
    <div>
      {/* <div>{JSON.stringify(signupUsers[0] || null)}</div> */}
      {/* <div className="text-center">{users.length} users <a href="#">Export</a></div> */}
      <div className="text-center">{users.length} users <CSVLink data={csvData} filename="fit-users-2022.csv">Export</CSVLink></div>
        <br/>
        {/* <div>
          {userSpouse.map((u) => {
            return <div key={u.user_id}>{u.display_name} - {moment(u.hire_date).format('YYYY-MM-DD')} - {getTeamForUser(u)} - {u.user_id}</div>
          })}
        </div> */}
  <div className="flex justify-between">
    <div>
      <div className="font-extrabold">{oldSchool.length} old school</div>
      {oldSchool.map((u) => {
          return <div key={u.user_id}>{u.display_name} - {moment(u.hire_date).format('YYYY-MM-DD')}</div>
        })}
    </div>
        <br/>
    <div>
      <div className="font-extrabold">{middleSchool.length} mid school</div>
      {middleSchool.map((u) => {
          return <div key={u.user_id}>{u.display_name} - {moment(u.hire_date).format('YYYY-MM-DD')}</div>
        })}
    </div>
        <br/>
    <div>
      <div className="font-extrabold">{newSchool.length} new school</div>
      {newSchool.map((u) => {
          return <div key={u.user_id}>{u.display_name} - {moment(u.hire_date).format('YYYY-MM-DD')}</div>
      })}
    </div>
        {/* {users.map((u) => {
          return <div key={u.user_id}>{u.display_name} - {moment(u.hire_date).format('YYYY-MM-DD')}</div>
        })} */}
    </div>
  </div>
  );
}