import React, { useState, useEffect, useReducer } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, orderBy, collectionGroup, where } from 'firebase/firestore';
import { transformFirestoreDates } from '../../common/utils';
import { db } from '../../common/firebase';

// TODO: points per category?
const defaultTeam = {name: '', points: 0};
export default function useChallengeTeam({challengeId, teamId}) {
    const [team, setTeam] = useState(defaultTeam);
    const [members, setMembers] = useState([]);

    const [loadingTeam, setLoadingTeam] = useState(true);
    const [loadingMembers, setLoadingMembers] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      setLoadingTeam(true);
      setLoadingMembers(true);
      const teamRef = doc(db, 'challenges', challengeId, 'challenge_teams', teamId);
      const teamMemberQuery = query(collectionGroup(db, 'user_challenges'), where('team', '==', teamRef), orderBy('points', 'desc'));

      const unlistenTeam = onSnapshot(teamRef, (snap) => {
        const data = snap.exists ? snap.data() : defaultTeam;
        data.$id = snap.id || teamId;
        setTeam(data);
        setLoadingTeam(false);
      }, (err) => setError(err));
      const unlistenMembers = onSnapshot(teamMemberQuery, (querySnapshot) => {
        const memberResult = [];
        querySnapshot.docs.forEach((snap) => {
          const data = snap.data();
          data.$id = snap.id;
          memberResult.push(transformFirestoreDates(data));
        });
        setMembers(memberResult);
        setLoadingMembers(false);
      }, (err) => setError(err));
      return () => {
        unlistenTeam && unlistenTeam();
        unlistenMembers && unlistenMembers();
      };
    }, [challengeId, teamId]);

    return {
      team: {
        ...team,
        members
      },
      loading: loadingTeam || loadingMembers,
      error
    }
  }
